table {
    border-collapse: collapse;
    width: 50%;
    margin: auto;
}

.red {
  color: red;
}

th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}
th {
    background-color: #f2f2f2;
}
.regress-cell {
    background-color: #f1683f; /* Set your desired background color here */
}
.progress-cell {
    background-color: #afe6a4; /* Set your desired background color here */
}

.progress-bar {
    display: inline-block;
    width: 300px;
    height: 20px;
    background-color: #ddd;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
}

.progress {
    height: 100%;
    background-color: #4CAF50;
    border-radius: 10px;
}

th:nth-child(6) {
    border: 0;
}
td:nth-child(6) {
    border: 0;
}
.borderless-cell{
    border: none;
}

.hover-cell {
    position: relative;
  }
  
  .hidden-text {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
    width: 150px;
  }
  
  .hover-cell:hover .hidden-text {
    display: block;
  }
  .table-container {
    margin-bottom: 20px; /* Adjust the value as needed */
  }

/* 
---------------------------------------------------------------------
Mobile section 
---------------------------------------------------------------------
*/ 
.table-container {
    margin: 1%;
    width: 98%;
    overflow-x: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

@media (max-width: 1000px) {
    .table-container {
      width: 100%;
      overflow-x: scroll;
    }
  }



