.horizontal-content {
  display: flex;
  justify-content: space-between;
  margin: 0% 1% 1%;
  overflow-x: scroll;
}

.deets{
  text-decoration: none;
  color: black;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  margin-left: 1vh; 
  margin-right: 5vh; /* Space between items */
  padding: 1vh;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.deets:hover {
  background-color: #f0f0f0; /* Grey background on hover */
}

/* Remove the margin from the last item */
.deets:last-child {
  margin-right: 0;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}
  
.rounded-rectangle {
  height: 30px;
  line-height: 30px;
  color: white; /* Add text color to make the content visible */
  text-align: center; /* Center the text horizontally */
  display: flex;
  gap: 20px;
}
.goal {
  width: 90px;
  background-color: rgb(238, 180, 71);
  border-radius: 15px;
}

.timeline {
  width: 90px;
  background-color: rgb(115, 236, 115);
  border-radius: 15px;
}
.strike-through {
  text-decoration: line-through;
}

.schedule{
  background-color: rgb(88, 148, 228);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1% 1% 10% 1%;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.schedule-title{
  margin: 5% 
}
#title {
  font-size: 44px;
}
#subtitle {
  font-size: 22px;
  text-align: center;

}

.progress-section{
  text-align: center; 
  padding-top: 30px; /* Add padding to the top of the text */
  padding-bottom: 30px; 
}

