header {
  background-image: url("https://sticktothick.s3.us-west-1.amazonaws.com/images/background-narrower.jpeg");;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:cover;
  padding: 10px;
  height: 400px;
  background-position: center center;
}
.header-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: 'Roboto', sans-serif;
}
.page-title {
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
  justify-content: space-between;
  color: white;
  font-family: 'Roboto', sans-serif;
}
.navigation {
  display: flex;
  align-items: center;
  
  gap: 50px;
  font-size: 16px;
  text-decoration: none;
}
.navs{
  font-size: 16px;
  text-decoration: none;
  color: white;
  font-family: 'Roboto', sans-serif;
}

/* 
---------------------------------------------------------------------
Mobile section 
---------------------------------------------------------------------
*/ 
/* Regular styles for larger screens */
.hamburger-menu {
  display: none; /* Hide on larger screens */
}
.mobile-nav {
  display: none;
}

/* Styles for mobile screens */
@media (max-width: 1000px) {
  .navigation {
      display: none;
  }
  .hamburger-menu {
    display: block; /* Show on mobile screens */
  }

  .hamburger-icon {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
  }

  .hamburger-icon span {
    display: block;
    height: 2px;
    width: 25px;
    background-color: #333;
    margin: 4px 0;
  }

  /* Hide the navigation menu by default */
  .mobile-nav {
    display: none;
  }

  /* Show the navigation menu when the hamburger icon is clicked */
  .mobile-nav.active {
    display: block;
  }

  .table-container {
        width: 100%;
        overflow-x: scroll;
      }
    }